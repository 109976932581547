import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store/index";

const routes: Array<RouteRecordRaw> = [
  // // 商品
  // {
  //   path: "/",
  //   name: "shop",
  //   component: () =>
  //     import(/* webpackChunkName: "shop-index" */ "../views/ShopIndex.vue"),
  //   meta: {
  //     title: "商品",
  //   },
  // },
  // 首页
  {
    path: "/:tenantId/:iccid",
    name: "home",
    component: HomeView,
    meta: {
      title: "流量充值",
    },
  },
  // 购买页面
  {
    path: "/package-buy",
    name: "package-buy",
    meta: {
      title: "购买套餐",
    },
    component: () =>
      import(/* webpackChunkName: "package-buy" */ "../views/PackageBuy.vue"),
  },
  // 购买成功页面
  {
    path: "/package-buy-success",
    name: "package-buy-success",
    meta: {
      title: "购买套餐",
    },
    component: () =>
      import(
        /* webpackChunkName: "package-buy-success" */ "../views/PackageBuySuccess.vue"
      ),
  },
  // 订购记录
  {
    path: "/order-records",
    name: "order-records",
    meta: {
      title: "订购记录",
    },
    component: () =>
      import(
        /* webpackChunkName: "order-records" */ "../views/OrderRecords.vue"
      ),
  },
  // 添加卡片
  {
    path: "/add-card",
    name: "add-card",
    meta: {
      title: "添加卡片",
    },
    component: () =>
      import(/* webpackChunkName: "add-card" */ "../views/AddCard.vue"),
  },
  // 客服
  {
    path: "/service",
    name: "service",
    meta: {
      title: "在线客服",
    },
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/ServiceView.vue"),
  },
  // 生成二维码
  {
    path: "/qr-code",
    name: "qr-code",
    meta: {
      title: "生成二维码",
    },
    component: () =>
      import(/* webpackChunkName: "qr-code" */ "../views/QRCodeTool.vue"),
  },
  {
    path: "/diagnosis",
    name: "diagnosis",
    meta: {
      title: "智能诊断",
    },
    component: () =>
      import(/* webpackChunkName: "diagnosis" */ "../views/Diagnosis.vue"),
  },
  {
    path: "/pay-interpage",
    name: "pay-interpage",
    component: () =>
      import(/* webpackChunkName: "diagnosis" */ "../views/payInterpage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

if (window.localStorage.getItem("tenantId")) {
  store.commit("setTenantId", window.localStorage.getItem("tenantId"));
}

if (window.localStorage.getItem("userId")) {
  store.commit("setUserId", window.localStorage.getItem("userId"));
}

if (window.localStorage.getItem("appId")) {
  store.commit("setAppId", window.localStorage.getItem("appId"));
}

if (window.localStorage.getItem("iccid")) {
  store.commit("setIccid", window.localStorage.getItem("iccid"));
}

if (window.localStorage.getItem("source")) {
  store.commit("setSource", window.localStorage.getItem("source"));
}

router.beforeEach((to, from, next) => {
  const title: string = (to.meta?.title ? to.meta.title : "") as string;
  document.title = title;
  next();
});

export default router;
