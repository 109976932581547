import router from "@/router";
import { RouteLocationNormalizedLoaded } from "vue-router";

interface customRouterOptions {
  tenantId?: string;
  iccid?: string;
  appId?: string;
}

export function isWeChat(): boolean {
  const ua: string = window.navigator.userAgent.toLowerCase(); // 不加window部分Android机会显示不了提示图，即判断不了是否是微信
  const match: RegExpMatchArray | null = ua.match(/MicroMessenger/i);
  return !!match && match[0] == "micromessenger";
}

export function customRouterPush(
  path: string,
  route: RouteLocationNormalizedLoaded,
  options: customRouterOptions = {}
): void {
  const { params, query } = route;
  const { tenantId, iccid, appId } = options;

  if (tenantId) params.tenantId = tenantId;
  if (iccid) params.iccid = iccid;
  if (appId) query.appId = appId;

  window.location.href = `${path}/${params.tenantId}/${params.iccid}?id=${query.id}&user=${query.user}`;
}
