import { httpRequest } from "./request";

// 套餐分页查询
export const apiGetPackageList = (params: any) =>
  httpRequest.get("/anyUser/package/ordinary/list", params);
// 套餐分页查询 - 带样式
export const apiGetPackageStyleList = (params: any) =>
  httpRequest.get("/anyUser/package/ordinary/list/style", params);

// 根据iccid获取卡板状态
export const apiGetCardState = (params: any) =>
  httpRequest.get("/anyUser/package/ordinary/fetchCard", params);

// 根据userId获取iccid
export const apiGetIccidByUserId = (params: any) =>
  httpRequest.get("/anyUser/package/ordinary/fetchCardByUser", params);

// 根据iccid获取本期订购套餐
export const apiGetCurrentPackage = (params: any) =>
  // httpRequest.get("/anyUser/package/ordinary/currentAll", params);
  httpRequest.get("/anyUser/cardAndOrder/info", params);

// 诊断卡板
export const apiDiagnosis = (data: any) =>
  httpRequest.post("/anyUser/card/diagnosis", data);

// 查询流量订购记录
export const apiGetFlowOrderRecords = (params: any) =>
  httpRequest.get("/anyUser/order/pay", params);

// 根据iccid查询是否订购过
export const apiGetHisOrderFLowFlag = (params: any) =>
  httpRequest.get("/anyUser/order/pay/his/flag", params);

// 产品页信息查询
export const apiGetPackageInfo = (data: any) =>
  httpRequest.post("/anyUser/order/package/info", data);

// 下单
export const apiOrder = (data: any) =>
  httpRequest.post("/anyUser/do/order", data);

// 刷新令牌
export const getRefresh = (params: any) =>
  httpRequest.get("/anyTenant/user/refresh", params);

// 续约
export const getAccessToken = (params: any) =>
  httpRequest.get("/anyTenant/user/renewToken", params);

// 校验订单是否支付
export const getOrderStatus = (params: any) =>
  httpRequest.get(`/anyUser/do/order/check/${params}`);

// 查询未支付订单
export const apiCheckUnpaidOrder = (iccid: string) =>
  httpRequest.get(`/anyUser/order/package/notPay/${iccid}`);

// 重新支付
export const apiRePay = (orderNo: string) =>
  httpRequest.put("/anyUser/do/order/again", { orderNo });

// 取消订单
export const apiPayClose = (orderNo: string) =>
  httpRequest.put("/anyUser/do/order/close", { orderNo });

// 卡板列表
export const apiGetCardList = (
  iccid: string,
  pageQuery: any,
  userId: string | null
) =>
  httpRequest.get("/anyUser/card/listPhoneCardByUserId", {
    iccid,
    ...pageQuery,
    userId,
  });

// 卡板列表(中维)
export const apiGetCardListZW = (iccid: string, pageQuery: any) =>
  httpRequest.get("/anyUser/card/listPhoneCardByAccessToken", {
    iccid,
    ...pageQuery,
  });

// 微信授权
export const apiWeChatVerify = (iccid: string, id: string) =>
  httpRequest.get("/anyUser/wechat/user/goto", { iccid, id });

// 设置卡板别名
export const apiSetCardName = (data: any) =>
  httpRequest.post("/anyUser/card/updateRemark", data);

// 添加卡板
export const apiAddCard = (data: any) =>
  httpRequest.post("/anyUser/card/wechat/bind", data);

// 微信信息注册
export const apiWeChatConfig = (data: any) =>
  httpRequest.post("/anyUser/wechat/jsApi/open", data);

// 获取代理商应用配置
export const apiGetAgentApplicationConfig = (params: any) =>
  httpRequest.get("/anyUser/application/config/agent/config", params);

// 获取首页弹窗信息
export const apiGetHomePagePopupInfo = (params: any) =>
  httpRequest.get("/anyUser/popup", params);

// 校验实名
export const apiGetRealNameInfo = (params: any) =>
  httpRequest.get("/anyUser/popup/realname", params);

// 校验卡板订购套餐
export const apiOrderPackageCheck = (params: any) =>
  httpRequest.get("/anyUser/popup/orderPackage", params);

// 删除卡板
export const delCardInfo = (params: any) =>
  httpRequest.post("/anyUser/card/unBindUserCard", params);

// 查询实名状态
export const postCardDiagnosisRealNameStatus = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/realNameStatus", params);

// 查询套餐订购
export const postCardDiagnosisPackageSubscription = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/packageSubscription", params);

// 查询套餐执行
export const postCardDiagnosisPackageExecution = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/packageExecution", params);

// 查询卡板状态
export const postCardDiagnosisCardStatus = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/cardStatus", params);

// 查询IME码识别
export const postCardDiagnosisImeiCodeRecognition = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/imeiCodeRecognition", params);

// 查询IME码变更
export const postCardDiagnosisImeiCodeChanged = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/imeiCodeChanged", params);

// 一键修复
export const postCardDiagnosisOneClickRepair = (params: any) =>
  httpRequest.post("/anyUser/card/diagnosisV2/oneClickRepair", params);

export const postCardDiagnosisSyncCardAllInfo = (params: any) =>
  httpRequest.post(`/anyUser/card/diagnosisV2/syncCardAllInfo?iccid=${params}`);

export const getAnyUserPackageOrdinaryExperience = (params: any) =>
  httpRequest.get("/anyUser/package/ordinary/experience", params);

export const postAnyUserDoOrderFreeOrder = (params: any) =>
  httpRequest.post("/anyUser/do/order/freeOrder", params);

// 查询套餐样式
// export const apiGetPackageStyle = (params: any) =>
//   httpRequest.get("/anyUser/card/package-group/query/style", params);
// 获取微信js签名
export const getJsSignature = (params: any) =>
  httpRequest.post("/anyUser/wechat/jsApi/getJsSignature", params);
