import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Toast style
import "vant/es/toast/style";
// Dialog style
import "vant/es/dialog/style";
// Notify style
import "vant/es/notify/style";
// ImagePreview style
// import 'vant/es/image-preview/style';

const app = createApp(App);
app.config.globalProperties.isCustomElement = (tag: any) =>
  tag.startsWith("wx-open-launch-weapp");

app.use(store).use(router).mount("#app");
