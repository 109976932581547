/*
 * @Author: 韩明涛 hamiguaex@163.com
 * @Date: 2023-07-11 16:11:30
 * @LastEditors: hujie striveyf@dingtalk.com
 * @LastEditTime: 2023-12-08 09:01:18
 * @FilePath: \tme-h5\src\common\request.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
// import router from "@/router";
import { showToast } from "vant";
import store from "../store/index";

class Request {
  // private baseUrl = "/api/tme/front";
  private baseUrl = process.env.VUE_APP_PROXY_PATH;

  private request(opts: AxiosRequestConfig): any {
    const instance: AxiosInstance = axios.create({
      baseURL: this.baseUrl,
      timeout: 30000,
    });
    this.setInterceptors(instance);
    return instance(opts);
  }
  //拦截器
  private setInterceptors(instance: AxiosInstance) {
    //请求拦截器
    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem("token");
      const tenantId = localStorage.getItem("tenantId");
      config.headers.TenantId = tenantId;
      config.headers.grayversion =
        tenantId === "283722700600378019" ? "283722700600378019" : "prod";
      if (token && config.headers) {
        config.headers["Authorization"] = token;
      }
      return config;
    });
    //响应拦截器
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.status == 200) {
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(res.data);
        }
      },
      (err) => {
        switch (err.response.status) {
          case 401:
            showToast("用户信息过期，请重新登录");
            setTimeout(() => {
              // router.push("/login");
            }, 1000);
            break;
          default:
            console.warn(`status= ${status}`);
            break;
        }
        return Promise.reject(err);
      }
    );
  }

  //封装get请求
  public get(url: string, params?: any) {
    const method = "get";
    return this.request({ url, method, params });
  }

  //封装post请求
  public post(url: string, data?: any, params?: any) {
    const method = "post";
    return this.request({ url, method, data, params });
  }

  //封装put请求
  public put(url: string, data?: any, params?: any) {
    const method = "put";
    return this.request({ url, method, data, params });
  }
}
export const httpRequest = new Request();
