import { createStore } from "vuex";

export default createStore({
  state: {
    tenantId: null,
    userId: null,
    appId: null,
    iccid: null,
    source: null,
  },
  getters: {},
  mutations: {
    setUserId(state, data) {
      state.userId = data;
      localStorage.setItem("userId", data);
    },
    setAppId(state, data) {
      state.appId = data;
      localStorage.setItem("appId", data);
    },
    setTenantId(state, data) {
      state.tenantId = data;
      localStorage.setItem("tenantId", data);
    },
    setIccid(state, data) {
      state.iccid = data;
      localStorage.setItem("iccid", data);
    },
    setSource(state, data) {
      state.source = data;
      localStorage.setItem("source", data);
    },
  },
  actions: {},
  modules: {},
});
